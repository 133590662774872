import React from "react";

// const Environment = "PRODUCTION";
// const Environment = "TESTING";
const Environment = "DEVELOPMENT";
// const Environment = "LOCAL";

const URL =
  Environment === "PRODUCTION"
    ? "https://managepharmacys.com"
    : Environment === "TESTING"
    ? "https://testing.managepharmacys.com"
    : Environment === "DEVELOPMENT"
    ? "https://development.managepharmacys.com"
    : Environment === "LOCAL"
    ? "http://localhost:5002"
    : "";

export default URL;
