import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Settings/Users/forms";
import headers from "../../../../Data/Forms/Settings/Users/headers";
import branches from "../../../../Data/branch.json";
import roles from "../../../../Data/roles.json";
import {
  create_user,
  get_user,
  update_user,
} from "../../../../Controllers/Settings/Users";
import Loadercomponent from "../../../../Componets/Loader";
import toast from "react-hot-toast";

const UsersForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const role = id
        ? await update_user({
            id: id,
            ...data?.data,
          })
        : await create_user(data?.data);

      if (role?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(role?.message);
        setButtonLoader(false);
      } else {
        toast?.error(role?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (user) => {
    let name = user?.name ? user?.name : "";
    let phone = user?.phone ? user?.phone : "";
    let branch = user?.branch?._id ? user?.branch?._id : "";
    let role = user?.role?._id ? user?.role?._id : "";
    let email = user?.email ? user?.email : "";
    let password = "";
    let status = user?.status ? user?.status : 0;

    let branch_options = [
      {
        value: user?.branch?._id,
        label: user?.branch?.name,
      },
    ];

    let role_options = [
      {
        value: user?.role?._id,
        label: user?.role?.name,
      },
    ];

    forms[0].options[0].form.value = name;
    forms[0].options[1].form.value = phone;

    forms[1].options[0].form.value = branch;
    forms[1].options[0].form.options = branch_options;

    forms[1].options[1].form.value = role;
    forms[1].options[1].form.options = role_options;

    forms[2].options[0].form.value = email;
    forms[2].options[1].form.value = password;

    forms[3].options[0].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const role = await get_user(data);

      if (role?.status) {
        handleData(role?.data);
      } else {
        toast?.error(role?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default UsersForm;
