import { AutoComplete, DatePicker, Form, Input, Select } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import AddButton from "../../../../Componets/Buttons/add";
import { select_id, select_search } from "../../../../Controllers/Global";
import dayjs from "dayjs";
import moment from "moment";
import taxes from "../../../taxes.json";
import payment_types from "../../../payment_types.json";
import { get_product } from "../../../../Controllers/Products/Products";
import Error from "../../../../Componets/Error";
import Modalcomponent from "../../../../Componets/Modal";

const RequestTable = React.forwardRef((props, focus) => {
  const { details, setDetails, totals, setTotals } = props;

  focus.current[`delivery_date`] = createRef();
  focus.current[`payment_types`] = createRef();

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [selcectedModalData, setSelcectedModalData] = useState();

  const handleSearch = async (e, index) => {
    let data = [...details];

    const select = {
      api: "get-all-products",
      search: e,
      status: 1,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.name });
      });

      data[index].options = options;

      setDetails(data);
    } else {
      toast?.error(search?.message);
    }
  };

  const handleModal = (modaldata) => {
    let data = [...details];

    let product = selcectedModalData;
    let index = selcectedModalData?.index;

    let product_description = null;
    let product_name = "";
    let product_unit = "";
    let product_unit_name = "";
    let product_conversion = 0;
    let product_unit_details = [];

    let description_ids = [];

    data?.map((v, i) => {
      description_ids?.push(v?.unit);
    });

    if (!description_ids?.includes(modaldata)) {
      if (product?.data?._id == modaldata) {
        product_description = product?.data?._id;
        product_name = product?.data?.name;
        product_unit = modaldata;
        product_unit_name = product?.data?.unit?.name;

        if (product?.data?.product_units_details?.length > 0) {
          product?.data?.product_units_details?.map((value, index) => {
            product_unit_details?.push({
              ...value,
              name: value?.name?.name,
              price_per_unit: 0,
              sale_price: "",
              unit_quantity: 0,
              unit_delivered: 0,
            });
          });
        }
      } else {
        let selected_unit = "";
        let unit_ids = [];

        if (product?.data?.product_units_details?.length > 0) {
          product?.data?.product_units_details?.map((value, index) => {
            unit_ids?.push(value?._id);
          });
        }

        if (unit_ids?.includes(modaldata)) {
          selected_unit =
            product?.data?.product_units_details?.[
              unit_ids?.indexOf(modaldata)
            ];
        }

        product_description = product?.data?._id;
        product_name = product?.data?.name;
        product_unit = modaldata;
        product_unit_name = selected_unit?.name?.name;
        product_conversion = selected_unit?.conversion;
      }

      data[index].description = product_description;
      data[index].name = product_name;
      data[index].unit = product_unit;
      data[index].unit_name = product_unit_name;
      data[index].quantity = "";
      data[index].conversion = product_conversion;
      data[index].unit_details_options = product_unit_details;

      setDetails(data);
      setModalData();
      setSelcectedModalData();
      focus.current[`quantity${index}`].current.focus();
    } else {
      let selected_index = description_ids?.indexOf(modaldata);
      let quantity = data[selected_index].quantity
        ? data[selected_index].quantity
        : 0;

      // if (
      //   data?.[selected_index]?.conversion > 0 &&
      //   data?.[selected_index]?.quantity + parseFloat(1) >=
      //     data?.[selected_index]?.conversion
      // ) {
      //   toast?.error(
      //     `Quantity exceeding ${data?.[selected_index]?.conversion}`
      //   );
      // } else {
      //   data[selected_index].quantity = parseFloat(quantity) + parseFloat(1);
      // }

      setDetails(data);
      setModalData();
      setSelcectedModalData();
      setTimeout(() => {
        focus.current[`quantity${selected_index}`].current.focus();
      }, 300);
    }
  };

  const handleDescription = async (e, index) => {
    let data = [...details];

    if (e) {
      let params = {
        id: e,
      };
      const product = await get_product(params);

      if (product?.data?.product_units_details?.length > 0) {
        setOpen(true);
        setModalData([
          { ...product?.data?.unit, _id: product?.data?._id },
          ...product?.data?.product_units_details,
        ]);

        setSelcectedModalData({ ...product, index: index });
      } else {
        let description_ids = [];

        data?.map((v, i) => {
          description_ids?.push(v?.description);
        });

        if (!description_ids?.includes(e)) {
          let product_description = product?.data?._id;
          let product_name = product?.data?.name;
          let product_unit = product?.data?._id;
          let product_unit_name = product?.data?.unit?.name;

          let product_unit_details = [];
          if (product?.data?.product_units_details?.length > 0) {
            product?.data?.product_units_details?.map((value, index) => {
              product_unit_details?.push({
                ...value,
                name: value?.name?.name,
                price_per_unit: 0,
                sale_price: "",
                unit_quantity: 0,
                unit_delivered: 0,
              });
            });
          }

          data[index].description = product_description;
          data[index].name = product_name;
          data[index].unit = product_unit;
          data[index].unit_name = product_unit_name;
          data[index].quantity = "";
          data[index].unit_details_options = product_unit_details;
          setDetails(data);

          setTimeout(() => {
            focus.current[`quantity${index}`].current.focus();
          }, 300);
        } else {
          let selected_index = description_ids?.indexOf(e);
          let quantity = data[selected_index].quantity
            ? data[selected_index].quantity
            : 0;

          // data[selected_index].quantity = parseFloat(quantity) + parseFloat(1);
          setDetails(data);

          setTimeout(() => {
            focus.current[`quantity${selected_index}`].current.focus();
          }, 300);
        }
      }
    } else {
      data[index].id = "";
      data[index].description = null;
      data[index].name = "";
      data[index].unit = "";
      data[index].unit_name = "";
      data[index].unit_details_options = null;
      data[index].quantity = "";
      data[index].conversion = 0;
      data[index].purchase = "";
      data[index].purchase_price = "";
      data[index].purchase_options = null;
      data[index].delivered = "";
      data[index].tax = 0;
      data[index].free = "";
      data[index].barcode = "";
      data[index].price_per_unit = "";
      data[index].sale_price = "";
      data[index].expiry_date = "";
      data[index].tax_amount = "0.000";
      data[index].total = 0;
      setDetails(data);
    }
  };

  const handleTotal = (e, index, key) => {
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    if (value >= 0) {
      let data = [...details];

      let data_purchase_price = data[index]?.purchase_price
        ? data[index]?.purchase_price
        : 0;
      let data_quantity = data[index]?.quantity ? data[index]?.quantity : 0;
      let data_deliverd = data[index]?.delivered ? data[index]?.delivered : 0;
      let data_free = data[index]?.free ? data[index]?.free : 0;
      let data_tax = data[index]?.tax ? data[index]?.tax : 0;
      let data_conversion = data[index].conversion ? data[index].conversion : 0;

      let purchase_price =
        name === "purchase_price" ? value : data_purchase_price;
      let quantity = name === "quantity" ? value : data_quantity;
      let delivered = name === "quantity" ? "" : data_deliverd;
      let free = name === "free" ? value : data_free;
      let tax = name === "tax" ? value : data_tax;

      let tax_amount = tax
        ? parseFloat(purchase_price) *
          parseFloat(quantity ? quantity : 0) *
          (parseFloat(tax) / 100)
        : 0;

      let total =
        parseFloat(purchase_price) * parseFloat(quantity ? quantity : 0) +
        parseFloat(tax_amount);

      let price_per_unit =
        parseFloat(total ? total : 0) /
        (parseFloat(quantity ? quantity : 0) + parseFloat(free ? free : 0));

      let unit_details_options = [];
      if (data?.[index]?.unit_details_options?.length > 0) {
        data?.[index]?.unit_details_options?.map((v, i) => {
          let total_quantity =
            parseFloat(quantity || 0) + parseFloat(free || 0);

          unit_details_options?.push({
            ...v,
            //price_per_unit
            price_per_unit: price_per_unit
              ? parseFloat(price_per_unit || 0) / parseFloat(v?.conversion || 0)
              : 0,
            //unit_quantity
            unit_quantity: quantity
              ? parseFloat(v?.conversion || 0) * parseFloat(total_quantity || 0)
              : 0,
          });
        });
      }

      if (
        data_conversion > 0 &&
        parseFloat(quantity) + parseFloat(free) >= data_conversion
      ) {
        toast?.error(`Quantiy exceeding ${data[index].conversion}`);
      } else {
        data[index][name] = value;
        data[index].unit_details_options = unit_details_options;
        data[index].delivered = delivered ? delivered : "";
        data[index].tax = tax ? tax : 0;
        data[index].total = total ? total : 0;
        data[index].tax_amount = tax_amount ? tax_amount : "0.000";
        data[index].price_per_unit = price_per_unit ? price_per_unit : 0;

        setDetails(data);
      }
    }
  };

  const handleRemove = (index) => {
    let data = [...details];

    data?.splice(index, 1);

    setDetails(data);
  };

  const handleAdd = () => {
    setDetails((prevDetails) => [
      ...prevDetails,
      {
        id: "",
        description: null,
        name: "",
        unit: "",
        unit_name: "",
        unit_details_options: null,
        quantity: "",
        purchase: "",
        purchase_price: "",
        purchase_options: null,
        delivered: "",
        tax: 0,
        free: "",
        barcode: "",
        price_per_unit: "",
        sale_price: "",
        expiry_date: "",
        tax_amount: "0.000",
        total: 0,
      },
    ]);

    setTimeout(() => {
      focus.current[`description${details?.length}`].current.focus();
    }, 100);
  };

  return (
    <div>
      <Modalcomponent
        open={open}
        setOpen={setOpen}
        modalData={modalData}
        setModalData={setModalData}
        selcectedModalData={selcectedModalData}
        setSelcectedModalData={setSelcectedModalData}
        handleModal={handleModal}
      />
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">S.NO</td>
            <td className="border p-4 text-black">DESCRIPTION</td>
            <td className="border p-4 text-black">UNIT</td>
            <td className="border p-4 text-black">QUANTITY</td>
            <td className="border p-4 text-black">DELETE</td>
          </tr>
        </thead>
        <tbody>
          {details?.map((value, index) => {
            focus.current[`description${index}`] = createRef();
            focus.current[`purchase_price${index}`] = createRef();
            focus.current[`quantity${index}`] = createRef();
            focus.current[`sale_price${index}`] = createRef();
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-center text-gray-700">
                    {index + 1}
                  </td>
                  <td className="border p-4">
                    {value?.description ? (
                      <div>{value?.name}</div>
                    ) : (
                      <div>
                        <Select
                          className="w-full text-regular"
                          name="description"
                          ref={focus.current[`description${index}`]}
                          placeholder="Description"
                          allowClear={true}
                          showSearch={true}
                          filterOption={false}
                          value={value?.description}
                          options={value?.options}
                          onSearch={(e) => handleSearch(e, index)}
                          onClick={(e) => handleSearch("", index)}
                          onFocus={(e) => handleSearch("", index)}
                          onChange={(e) => handleDescription(e, index)}
                          autoComplete="off"
                        />
                      </div>
                    )}
                    <Error id={`description${index}`} />
                  </td>
                  <td className="border p-4 text-center">
                    {/* <div>{value?.unit}</div> */}
                    <div>{value?.unit_name}</div>
                  </td>
                  <td className="border p-4 text-center">
                    <div>
                      <Input
                        type="number"
                        className="w-full text-regular"
                        name="quantity"
                        ref={focus.current[`quantity${index}`]}
                        placeholder="Quantity"
                        value={value?.quantity}
                        onChange={(e) => handleTotal(e, index)}
                        autoComplete="off"
                      />
                      <div className="">
                        <Error id={`quantity${index}`} />
                      </div>
                    </div>
                  </td>
                  <td className="border p-4 text-center">
                    <button
                      type="button"
                      className="text-lg text-red-500 cursor-pointer hover:text-red-800"
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
                <tr className="">
                  <td className="border border-gray-200 border-b-gray-400 p-4 text-center"></td>
                  <td className="border border-gray-200 border-b-gray-400  p-4"></td>
                  <td className="border border-gray-200 border-b-gray-400 p-4 text-center"></td>
                  <td className="border border-gray-200 border-b-gray-400 p-4">
                    {/* units details unit_quantity */}
                    <div>
                      {value?.unit_details_options?.map((v, i) => {
                        return (
                          <div className="pt-2">
                            <div className="pb-2 text-gray-700 uppercase">
                              {v?.name}({v?.conversion})
                            </div>
                            <div>
                              <div className="border border-gray-200 p-1 rounded-md">
                                <div>{v?.unit_quantity}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td className="border border-gray-200 border-b-gray-400 p-4 text-center"></td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="border p-4" rowSpan={2} colSpan={3}></td>
            <td className="border p-4" rowSpan={2} colSpan={3}>
              <div className="text-center">
                <AddButton
                  className={"form-save-button w-full h-full text-center"}
                  onClick={handleAdd}
                />
              </div>
            </td>
          </tr>
          <tr></tr>
        </tfoot>
      </table>
    </div>
  );
});

export default RequestTable;
