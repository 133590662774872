const forms = [
  {
    name: "Inventory ransfer",
    className: "bg-white p-4 rounded-md p-8 w-[90%] mx-auto",
    options: [
      {
        name: "Banch",
        type: "selectandadd",
        path: "/app/suppliers/add",
        form: {
          name: "supplier",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Branch",
          api: "get-all-branches",
          options: null,
          value: null,
          required: true,
        },
      },
      {
        name: "Number",
        type: "input",
        form: {
          type: "text",
          name: "number",
          placeholder: "Purchase return number",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "date",
          placeholder: "Purchase returns date",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Due Date",
        type: "date",
        form: {
          type: "text",
          name: "due_date",
          placeholder: "Purchase returns due date",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
