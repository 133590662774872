import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import tables from "../../../../Data/Tables/Inventory/Request/tables";
import headers from "../../../../Data/Tables/Inventory/Request/headers";
import received from "../../../../Data/received.json";
import ActionButton from "../../../../Componets/Buttons/action";
import Status from "../../../../Componets/Status";
import branches from "../../../../Data/branch.json";
import toast from "react-hot-toast";
import Loadercomponent from "../../../../Componets/Loader";
import { get_all_requests } from "../../../../Controllers/Inventory/Request";
import moment from "moment";

const InventoryRequestTable = () => {
  const [loader, setLoader] = useState(false);
  const [header, setHeader] = useState(headers);
  const [body, setBody] = useState();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    totalPages: 0,
  });

  const paginationdata = (data) => {
    let headerdata = {};
    headers?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      sort: headerdata?.sort,
      unit: headerdata?.unit,
      category: headerdata?.category,
      brand: headerdata?.brand,
      type: headerdata?.type,
      status: headerdata?.status,
    });
  };

  const formdata = (data) => {
    let page = data?.search ? pagination?.currentPage : 1;

    fetchData({
      ...data,
      currentPage: page,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const actiondata = (data) => {
    let headerdata = {};
    headers?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      sort: headerdata?.sort,
      unit: headerdata?.unit,
      category: headerdata?.category,
      brand: headerdata?.brand,
      type: headerdata?.type,
      status: headerdata?.status,
      currentPage: pagination?.currentPage,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        product: tabledata?.product,
        date: tabledata?.date,
        type: tabledata?.type,
        status: tabledata?.status,
        page: tabledata?.currentPage,
      };

      const products = await get_all_requests(data);
      if (products?.status) {
        let data = [];

        products?.data?.data?.map((value, index) => {
          data?.push({
            path: `/app/inventory/request/view/${value?._id}`,
            Number: `#REQUEST-${value?.number}`,
            Date: moment(value?.date).format("MMMM Do YYYY"),
            Branch: value?.supplier?.name,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/inventory/request/edit/${value?._id}`}
                id={value?._id}
                api="delete-request"
                actiondata={actiondata}
              />
            ),
          });
        });

        setPagination({
          currentPage: products?.data?.currentPage,
          totalCount: products?.data?.totalCount,
          totalPages: products?.data?.totalPages,
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(products?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table
          head={tables}
          body={body}
          pagination={pagination}
          paginationdata={paginationdata}
        />
      </Loadercomponent>
    </Header>
  );
};

export default InventoryRequestTable;
