const tables = [
  {
    name: "Number",
  },
  {
    name: "Date",
  },
  {
    name: "Branch",
  },
  {
    name: "Status",
  },
  {
    name: "Action",
  },
];

export default tables;
