import { Select } from "antd";
import React from "react";
import Error from "../Error";
import { select_search } from "../../Controllers/Global";

const Selectcomponent = React.forwardRef((props, focus) => {
  const { index, ind, val, form, setForm, getFormdata } = props;

  const handleSelect = (e, index, ind) => {
    let data = [...form];

    data[index].options[ind].form.value = e;

    if (data[index]?.options[ind]?.show?.check) {
      data?.map((value, k) => {
        value?.options?.map((val, i) => {
          if (val?.show?.name == data[index].options[ind].form.name) {
            data[k].options[i].show.status = e ? true : false;
          }
        });
      });
    }

    setForm(data);
    getFormdata(data);
  };

  const handleSearch = async (e, index, ind) => {
    let data = [...form];

    const select = {
      api: data[index].options[ind].form.api,
      search: e,
      status: 1,
    };
    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.name });
      });

      data[index].options[ind].form.options = options;
      setForm(data);
      getFormdata(data);
    } else {
      getFormdata(data);
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <Select
        key={index}
        id={index?.toString()?.concat(ind)}
        name={val?.form?.name}
        placeholder={val?.form?.placeholder}
        className={
          val?.form?.className ? val?.form?.className : "text-regular w-full"
        }
        suffixIcon={val?.form?.suffixIcon}
        ref={focus.current[val?.form?.name?.concat(index + "" + ind)]}
        size={val?.form?.size}
        mode={val?.form?.mode}
        allowClear={val?.form?.allowClear}
        showSearch={val?.form?.showSearch}
        filterOption={val?.form?.filterOption}
        options={val?.form?.options}
        value={val?.form?.value}
        onKeyDown={handleOnKeyDown}
        onClick={() => handleSearch("", index, ind)}
        onFocus={() => handleSearch("", index, ind)}
        onSearch={(e) => handleSearch(e, index, ind)}
        onChange={(e) => handleSelect(e, index, ind)}
        autoComplete="off"
      />
      <Error id={val?.form?.name?.concat(index + "" + ind)} />
    </React.Fragment>
  );
});

export default Selectcomponent;
