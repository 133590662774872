import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_receive = async (receive) => {
  try {
    const data = {
      transfer: receive?.transfer,
      purchase: receive?.purchase,
      supplier: receive?.supplier,
      project: receive?.project,
      number: receive?.number,
      date: receive?.date,
      due_date: receive?.due_date,
      status: receive?.status,
      details: receive?.details,
      subtotal: receive?.subtotal,
      taxamount: receive?.taxamount,
      discount: receive?.discount,
      delivery: receive?.delivery,
      delivery_status: receive?.delivery_status,
      delivery_date: receive?.delivery_date,
      payment_status: receive?.payment_status,
      payment_types: JSON?.stringify(receive?.payment_types),
      payments: JSON?.stringify(receive?.payments),
      total: receive?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-receive`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_receive = async (receive) => {
  try {
    const data = {
      id: receive?.id,
      purchase: receive?.purchase,
      supplier: receive?.supplier,
      project: receive?.project,
      number: receive?.number,
      date: receive?.date,
      due_date: receive?.due_date,
      status: receive?.status,
      details: receive?.details,
      subtotal: receive?.subtotal,
      taxamount: receive?.taxamount,
      discount: receive?.discount,
      delivery: receive?.delivery,
      delivery_status: receive?.delivery_status,
      delivery_date: receive?.delivery_date,
      payment_status: receive?.payment_status,
      payment_types: JSON?.stringify(receive?.payment_types),
      payments: JSON?.stringify(receive?.payments),
      total: receive?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-receive`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_receive = async (receive) => {
  try {
    const data = {
      id: receive?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/delete-receive`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_receive = async (receive) => {
  try {
    const data = {
      id: receive?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-receive`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_received = async (receive) => {
  try {
    const data = {
      search: receive?.search,
      sort: receive?.sort,
      supplier: receive?.supplier,
      contractor: receive?.contractor,
      date: receive?.date,
      due_date: receive?.due_date,
      page: receive?.page,
      status: receive?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-received`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_received_details = async (receive) => {
  try {
    const data = {
      search: receive?.search,
      status: receive?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-received-details`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_receive_log = async (receive) => {
  try {
    const data = {
      search: receive?.search,
      sort: receive?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-receive-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_receive_logs = async (receive) => {
  try {
    const data = {
      search: receive?.search,
      sort: receive?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-receive-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_receive,
  update_receive,
  delete_receive,
  get_receive,
  get_all_received,
  get_all_received_details,
  get_receive_log,
  get_all_receive_logs,
};
