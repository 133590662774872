import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_transfer = async (transfer) => {
  try {
    const data = {
      purchase: transfer?.purchase,
      supplier: transfer?.supplier,
      project: transfer?.project,
      number: transfer?.number,
      date: transfer?.date,
      due_date: transfer?.due_date,
      status: transfer?.status,
      details: transfer?.details,
      subtotal: transfer?.subtotal,
      taxamount: transfer?.taxamount,
      discount: transfer?.discount,
      delivery: transfer?.delivery,
      delivery_status: transfer?.delivery_status,
      delivery_date: transfer?.delivery_date,
      payment_status: transfer?.payment_status,
      payment_types: JSON?.stringify(transfer?.payment_types),
      payments: JSON?.stringify(transfer?.payments),
      total: transfer?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-transfer`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_transfer = async (transfer) => {
  try {
    const data = {
      id: transfer?.id,
      purchase: transfer?.purchase,
      supplier: transfer?.supplier,
      project: transfer?.project,
      number: transfer?.number,
      date: transfer?.date,
      due_date: transfer?.due_date,
      status: transfer?.status,
      details: transfer?.details,
      subtotal: transfer?.subtotal,
      taxamount: transfer?.taxamount,
      discount: transfer?.discount,
      delivery: transfer?.delivery,
      delivery_status: transfer?.delivery_status,
      delivery_date: transfer?.delivery_date,
      payment_status: transfer?.payment_status,
      payment_types: JSON?.stringify(transfer?.payment_types),
      payments: JSON?.stringify(transfer?.payments),
      total: transfer?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-transfer`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_transfer = async (transfer) => {
  try {
    const data = {
      id: transfer?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/delete-transfer`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_transfer = async (transfer) => {
  try {
    const data = {
      id: transfer?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-transfer`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_transfers = async (transfer) => {
  try {
    const data = {
      search: transfer?.search,
      sort: transfer?.sort,
      supplier: transfer?.supplier,
      contractor: transfer?.contractor,
      date: transfer?.date,
      due_date: transfer?.due_date,
      page: transfer?.page,
      status: transfer?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-transfers`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_transfers_details = async (transfer) => {
  try {
    const data = {
      search: transfer?.search,
      status: transfer?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-transfers-details`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_transfer_log = async (transfer) => {
  try {
    const data = {
      search: transfer?.search,
      sort: transfer?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-transfer-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_transfer_logs = async (transfer) => {
  try {
    const data = {
      search: transfer?.search,
      sort: transfer?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-transfer-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_transfer,
  update_transfer,
  delete_transfer,
  get_transfer,
  get_all_transfers,
  get_all_transfers_details,
  get_transfer_log,
  get_all_transfer_logs,
};
