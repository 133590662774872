import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_purchases_return = async (purchases_return) => {
  try {
    const data = {
      purchase: purchases_return?.purchase,
      supplier: purchases_return?.supplier,
      project: purchases_return?.project,
      number: purchases_return?.number,
      date: purchases_return?.date,
      due_date: purchases_return?.due_date,
      status: purchases_return?.status,
      details: purchases_return?.details,
      subtotal: purchases_return?.subtotal,
      taxamount: purchases_return?.taxamount,
      discount: purchases_return?.discount,
      delivery: purchases_return?.delivery,
      delivery_status: purchases_return?.delivery_status,
      delivery_date: purchases_return?.delivery_date,
      payment_status: purchases_return?.payment_status,
      payment_types: JSON?.stringify(purchases_return?.payment_types),
      payments: JSON?.stringify(purchases_return?.payments),
      total: purchases_return?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-purchases-return`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_purchases_return = async (purchases_return) => {
  try {
    const data = {
      id: purchases_return?.id,
      purchase: purchases_return?.purchase,
      supplier: purchases_return?.supplier,
      project: purchases_return?.project,
      number: purchases_return?.number,
      date: purchases_return?.date,
      due_date: purchases_return?.due_date,
      status: purchases_return?.status,
      details: purchases_return?.details,
      subtotal: purchases_return?.subtotal,
      taxamount: purchases_return?.taxamount,
      discount: purchases_return?.discount,
      delivery: purchases_return?.delivery,
      delivery_status: purchases_return?.delivery_status,
      delivery_date: purchases_return?.delivery_date,
      payment_status: purchases_return?.payment_status,
      payment_types: JSON?.stringify(purchases_return?.payment_types),
      payments: JSON?.stringify(purchases_return?.payments),
      total: purchases_return?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-purchases-return`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_purchases_return = async (purchases_return) => {
  try {
    const data = {
      id: purchases_return?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/delete-purchases-return`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_purchases_return = async (purchases_return) => {
  try {
    const data = {
      id: purchases_return?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-purchases-return`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_purchases_returns = async (purchases_return) => {
  try {
    const data = {
      search: purchases_return?.search,
      sort: purchases_return?.sort,
      supplier: purchases_return?.supplier,
      contractor: purchases_return?.contractor,
      date: purchases_return?.date,
      due_date: purchases_return?.due_date,
      page: purchases_return?.page,
      status: purchases_return?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-purchases-returns`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_purchases_returns_details = async (purchases_return) => {
  try {
    const data = {
      search: purchases_return?.search,
      status: purchases_return?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-purchases-returns-details`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_purchases_return_log = async (purchases_return) => {
  try {
    const data = {
      search: purchases_return?.search,
      sort: purchases_return?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-purchases-return-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_purchases_return_logs = async (purchases_return) => {
  try {
    const data = {
      search: purchases_return?.search,
      sort: purchases_return?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-purchases-return-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_purchases_return,
  update_purchases_return,
  delete_purchases_return,
  get_purchases_return,
  get_all_purchases_returns,
  get_all_purchases_returns_details,
  get_purchases_return_log,
  get_all_purchases_return_logs,
};
