import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_product = async (product) => {
  try {
    let data = new FormData();
    data.append("image", product?.image[0]);
    data.append("name", product?.name);
    data.append("barcode", product?.barcode);
    data.append("unit", product?.unit);
    data.append("category", product?.category);
    data.append("brand", product?.brand);
    data.append("stock", product?.stock);
    data.append("expiry", product?.expiry);
    data.append("tax", product?.tax);
    data.append("type", product?.type);
    data.append("status", product?.status);
    data.append("details", JSON?.stringify(product?.details));

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-product`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_product = async (product) => {
  try {
    let data = new FormData();
    data.append("id", product?.id);
    data.append("image", product?.image[0]);
    data.append("name", product?.name);
    data.append("barcode", product?.barcode);
    data.append("unit", product?.unit);
    data.append("category", product?.category);
    data.append("brand", product?.brand);
    data.append("stock", product?.stock);
    data.append("expiry", product?.expiry);
    data.append("tax", product?.tax);
    data.append("type", product?.type);
    data.append("status", product?.status);
    data.append("details", JSON?.stringify(product?.details));

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-product`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_product = async (product) => {
  try {
    const data = {
      id: product?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-product`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_products = async (product) => {
  try {
    const data = {
      search: product?.search,
      sort: product?.sort,
      unit: product?.unit,
      category: product?.category,
      brand: product?.brand,
      type: product?.type,
      status: product?.status,
      page: product?.page,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-products`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_products_reports = async (product) => {
  try {
    const data = {
      search: product?.search,
      sort: product?.sort,
      unit: product?.unit,
      category: product?.category,
      brand: product?.brand,
      type: product?.type,
      status: product?.status,
      page: product?.page,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-products-reports`,
      data,
      config
    );

    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_product_unit_detail = async (product) => {
  try {
    const data = {
      id: product?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-product-unit-detail`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_product_barcode = async (product) => {
  try {
    const data = {
      barcode: product?.barcode,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-product-barcode`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_product,
  update_product,
  get_product,
  get_all_products,
  get_all_products_reports,
  get_product_unit_detail,
  get_product_barcode,
};
