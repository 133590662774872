import React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

const ProfileButton = (props) => {
  let { body } = props;

  let navigate = useNavigate();
  return (
    <div
      className="bg-white py-2 px-4 w-full  cursor-pointer"
      onClick={() => navigate("/app/dashboard")}
    >
      <div className="flex justify-between">
        <div className="flex">
          <div>
            <Avatar
              name={body?.name}
              size={35}
              textSizeRatio={1.75}
              className="rounded-md"
            />
          </div>
          <div className="pl-2">
            <div className="text-wrap-limit text-sm" title={body?.name}>
              {body?.name}
            </div>
            <div className="text-xs text-regular text-gray-500">
              {/* {body?.role?.toUpperCase()} */}
              {body?.role == "SUPERADMIN"
                ? body?.role?.toUpperCase()
                : body?.role?.name?.toUpperCase()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileButton;
