import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const AddButton = (props) => {
  return (
    <Button className="form-save-button" {...props}>
      <div className="flex items-center justify-center">
        <div className="flex items-center">
          <PlusOutlined />
        </div>
        <div className="px-2 text-regular flex items-center">Add</div>
      </div>
    </Button>
  );
};

export default AddButton;
