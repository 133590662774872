import moment from "moment";
import React from "react";

const InvoiceA5 = React.forwardRef((props, ref) => {
  const { values } = props;

  console.log(values, "values?.values");

  return (
    <div ref={ref} style={styles.container}>
      {/* Billing values */}
      {/* <div style={styles.billingvalues}>
        <div className="text-xs text-regular">
          <span>Customer :</span> {props?.customerName}
        </div>
        <p>
          <strong>Address:</strong> {props?.customerAddress}
        </p>
        <p>
          <strong>Date:</strong> {props?.date}
        </p>
      </div> */}
      <div className="text-regular text-xs pb-6">
        <div className="flex justify-between">
          <div>
            <table>
              <tbody>
                <tr>
                  <td className="text-xs">
                    <td>Customer</td>
                    <td className="px-2">:</td>
                    <td className="text-right">
                      {values?.customer?.name
                        ? values?.customer?.name
                        : "Walk-in-customer"}
                      &nbsp;
                      {values?.customer?.phone
                        ? `(${values?.customer?.phone})`
                        : ""}
                    </td>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>Invoice No</td>
                  <td className="px-2">:</td>
                  <td className="text-right">#INV-{values?.number}</td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td className="px-2">:</td>
                  <td className="text-right">
                    {moment?.(values?.date)?.format?.("DD/MM/YYYY")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Items Table */}
      <table style={styles.table}>
        <thead>
          <tr style={styles.tableHeader}>
            <th className="text-xs text-regular" style={styles.tableCell}>
              S.No
            </th>
            <th className="text-xs text-regular" style={styles.tableCell}>
              Description
            </th>
            <th className="text-xs text-regular" style={styles.tableCell}>
              Units
            </th>
            <th className="text-xs text-regular" style={styles.tableCell}>
              Quantity
            </th>
            <th className="text-xs text-regular" style={styles.tableCell}>
              Price
            </th>
            <th className="text-xs text-regular" style={styles.tableCell}>
              Tax
            </th>
            <th className="text-xs text-regular" style={styles.tableCell}>
              Total
            </th>
          </tr>
        </thead>
        <tbody className="text-regular">
          {values?.details?.map((item, index) => (
            <tr key={index}>
              <td className="text-xs" style={styles.tableCell}>
                {index + 1}
              </td>
              <td className="text-xs" style={styles.tableCell}>
                <div>{item?.name}</div>
                <div>{item?.number ? `#BATCH : ${item?.number}` : ""}</div>
                <div>
                  {item?.description?.expiry_date
                    ? `EXPIRY : ${moment?.(
                        item?.description?.expiry_date
                      )?.format("DD-MM-YYYY")}`
                    : ""}
                </div>
              </td>
              <td className="text-xs" style={styles.tableCell}>
                {item?.unit_name}
              </td>
              <td className="text-xs" style={styles.tableCell}>
                {item?.quantity}
              </td>
              <td className="text-xs" style={styles.tableCell}>
                {item?.sale_price?.toFixed(3)}
              </td>
              <td className="text-xs" style={styles.tableCell}>
                {item?.tax_amount?.toFixed(3)}
              </td>
              <td className="text-xs" style={styles.tableCell}>
                {item?.total?.toFixed(3)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="text-regular">
          <tr style={styles.totalRow}>
            <td
              className="text-regular text-gray-700 text-medium text-xs"
              style={styles.tableCell}
              colSpan="6"
              align="right"
            >
              Subtotal
            </td>
            <td style={styles.tableCell}>{values?.subtotal?.toFixed(3)}</td>
          </tr>
          <tr style={styles.totalRow}>
            <td
              className="text-regular text-gray-700 text-medium text-xs"
              style={styles.tableCell}
              colSpan="6"
              align="right"
            >
              Tax [VAT]
            </td>
            <td style={styles.tableCell}>{values?.taxamount?.toFixed(3)}</td>
          </tr>
          <tr style={styles.totalRow}>
            <td
              className="text-regular text-gray-700 text-medium text-xs"
              style={styles.tableCell}
              colSpan="6"
              align="right"
            >
              Discount
            </td>
            <td style={styles.tableCell}>{values?.discount?.toFixed(3)}</td>
          </tr>
          {/* <tr style={styles.totalRow}>
            <td
              className="text-regular text-gray-700 text-medium text-xs"
              style={styles.tableCell}
              colSpan="5"
              align="right"
            >
              Delivery
            </td>
            <td style={styles.tableCell}>{values?.discount?.toFixed(3)}</td>
          </tr> */}
          <tr style={styles.totalRow}>
            <td
              className="text-regular text-medium text-xs"
              style={styles.tableCell}
              colSpan="6"
              align="right"
            >
              Total
            </td>
            <td
              className="text-regular text-medium text-xs"
              style={styles.tableCell}
            >
              {values?.total?.toFixed(3)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
});

const styles = {
  container: {
    width: "210mm", // A5 width
    height: "148mm", // A5 height
    padding: "38mm 20mm", // Adjust padding to avoid pre-printed header/footer
    fontFamily: "Arial, sans-serif",
    fontSize: "12px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
  },
  tableCell: {
    border: "1px solid #000",
    padding: "8px",
    textAlign: "left",
  },
  totalRow: {
    fontWeight: "normal",
  },
  billingvalues: {
    marginBottom: "20px",
  },
};

export default InvoiceA5;
